.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiPagination-ul .MuiPaginationItem-root {
  color: #868686;
  background-color: white;
}

.MuiPagination-ul .MuiPaginationItem-root.Mui-selected,
.MuiPagination-ul .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root:hover {
  color: #fff;
  background-color: #0097DA;
}

.MuiPagination-ul .MuiPaginationItem-ellipsis {
  color: #868686;
  background-color: white;
  height: 32px;
  width: 32px;
  border-radius: 0;
  padding-top: 3px;
}

.list-default ol,
.list-default ul {
  list-style: revert;
  padding-left: 1rem;
}

.list-default ul li::marker {
  color: #0097DA;
}

.list-default a {
  color: #0097DA;
  text-decoration: underline;
}

.list-default p {
  word-wrap: break-word;
}

h1 {
  font-size: 3rem;
  line-height: 1;
  font-weight: 500;
}

h2 {
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 500;
}

h3 {
  font-size: 1.875rem;
  line-height: 1.25;
  font-weight: 500;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 500;
}

h5 {
  font-size: 1.25em;
  line-height: 1.25;
  font-weight: 500;
}

h6 {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 500;
}

.rel-camp-text h1,
.rel-camp-text h2,
.rel-camp-text h3,
.rel-camp-text h4,
.rel-camp-text h5,
.rel-camp-text h6 {
  font-size: inherit;
}